import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { useGlobalState } from "../state/globalState";

export default function Datenschutzerklaerung({ data }: any) {
  const { datenschutzerklaerung } = data;
  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "light",
      hidden: false,
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <article className="py-32 prose container-5xl">
        <div dangerouslySetInnerHTML={{ __html: datenschutzerklaerung.html }} />
      </article>
    </>
  );
}

export const query = graphql`
  query DatenschutzerklaerungQuery($language: String!) {
    datenschutzerklaerung: markdownRemark(
      frontmatter: {
        templateKey: { eq: "datenschutzerklaerung" }
        language: { eq: $language }
      }
    ) {
      html
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
